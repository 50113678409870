import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";




export default class Item extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }

    }

    

    componentDidMount() {
        
    }


    render (){
        const {data} = this.props
        return (
            <Cont className="p-3 ">
                <Img src={data.IMAGE==null?null:`${global.URL_Main}/img/${data.IMAGE[0]}`}/>
                <Title>{data.TITLE}</Title>
                <Date>{global.makeNormalDate(data.DATE_CREATE,"dd.mm.yyyy")}</Date>
            </Cont>
        )
    }
}

const Cont = styled.div`

box-shadow:10px 10px 30px rgba(0,0,0,0.05);
border-radius:10px;
color:black;
`

const Img = styled.img`
width:100%;
height:200px;
object-fit:cover;
border-radius:10px;
background:rgb(240,240,240);
`

const Title = styled.p`
margin-top:10px;
margin-bottom:0px
`

const Date = styled.p`
margin-top:10px;
margin-bottom:0px;
font-size:12px;
`



