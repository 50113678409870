import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import Item from './Item';
import Input_image from '../../components/Input_image';
import axios from "axios"
import { encode } from "base-64"
import {openPopUp} from "../../../actions/PopUpActions"
import Alert from "../../PopUp/Views/Alert"


class NewsItem extends React.Component {
    constructor(props) {
        super()
        this.state = {
            NewsData:[],
            SelfData:{},
            imgUrl:null,

            textData:''
        }

        this.Main_img=null
        this.filesToUpload=[]
        this.filesToDelete=[]
        this.input={}

        this.Mainfields=[
            {
                name:"TITLE",
                descr:"Заголовок"
            },
            {
                name:"TEXT",
                descr:"Текст новости",
                type:"textarea",
                onChange:(e)=>{
                    this.setState({textData:e.target.value})
                },
                style:{
                    resize:"none",
                    height:300
                }
            }
        ]
    }

    

    componentDidMount() {
        if(this.props.match.params.ID_OBJECT!="new"){
            global.API_task({
                operation:"getNews",
                ID:this.props.match.params.ID_OBJECT
            }).then(res => {
                res[0].IMAGE=JSON.parse(res[0].IMAGE)
                
                this.setState({SelfData:res[0],textData:res[0].TEXT})
                this.Mainfields.map((field)=>{
                    this.input[field.name].value=res[0][field.name]!=null?res[0][field.name]:null
                })
                
                this.input.DATE_CREATE.value=res[0].DATE_CREATE.substr(0,10)
                
            })
        }
    }

    Delete(){
        let data={
            operation:"deleteNews",
            ID:this.props.match.params.ID_OBJECT
        }
        global.API_task(data).then(() => {
            window.location.replace(`./`)
        })
    }


    Save(){
        if(!global.checkReqvireds(this.input,["TEXT"])) return

        let data={operation:"createUpdateNews"}

        this.Mainfields.map((field)=>{
            data[field.name]=this.input[field.name].value;
        })
        data.DATE_CREATE = this.input.DATE_CREATE.value
        
        if(this.props.match.params.ID_OBJECT=="new"){
            if(this.filesToUpload.length==0 ) {
                this.props.openPopUp(<Alert text="Нужно выбрать хотябы одно изображение для новости"/>)
                return
            }


            global.API_task(data).then(res => {
                
               debugger

                let form = new FormData();
                for(let i=0;i<this.filesToUpload.length;i++){
                    form.append('file'+i, this.filesToUpload[i])
                }
                

                axios({
                    method:'post',
                    url:global.serverURL+`operation=setExpoIMAGE&ID=${res[0].ID}&type=NEWS`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization:'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
                    },
                    data:form
                }).then( (datag) => {
                    
                    window.location.replace(`./${res[0].ID}`)
                })
                

                
            })

        }else{
            data.ID=this.props.match.params.ID_OBJECT

            if(this.filesToDelete.length>0){
                global.API_task({
                    operation:"deletePhoto",
                    ID:this.props.match.params.ID_OBJECT,
                    type:"NEWS",
                    filesToDelete:JSON.stringify(this.filesToDelete)
                })
            }

            global.API_task(data).then(res => {
                
                if(this.filesToUpload.length==0){
                    window.location.reload()
                    return
                }
                

                    let form = new FormData();
                    for(let i=0;i<this.filesToUpload.length;i++){
                        form.append('file'+i, this.filesToUpload[i])
                    }
                
                    
                    axios({
                        method:'post',
                        url:global.serverURL+`operation=setExpoIMAGE&ID=${res[0].ID}&type=NEWS`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization:'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
                        },
                        data:form
                    }).then( res => {
                        debugger
                        window.location.reload()
                    })

                
            })
        }
    }

    makeMain(i){
        const {SelfData} = this.state
        
        var SelfData_tmp  = SelfData.IMAGE.splice(0)
        var mainImg  = SelfData_tmp[i]
        SelfData_tmp.splice(i,1)
        SelfData_tmp=[mainImg,...SelfData_tmp]
        
        global.API_task({
            operation:"setImgOrder",
            TYPE:'NEWS',
            ID:SelfData.ID,
            IMGS_ORDER:JSON.stringify(SelfData_tmp)
        }).then(res => {
           this.componentDidMount()
        })
    }

    
    generateText(textData){
        const {SelfData} = this.state
        const data = SelfData
    
        let result = []
        let objs =  textData.match(/(#[0-9]#)/g)
        if(objs!=null && objs.length>0)
          objs.forEach(obj=>{
            let imgNumber = obj.replace('#','').replace('#','')*1
            result.push(<PNew>{textData.substr(0, textData.indexOf(obj))}</PNew>)
            if(data.IMAGE!=null && data.IMAGE[imgNumber]!=null)
                result.push(<ImgNew src={`${global.URL_host}img/${data.IMAGE[imgNumber]}`} />)
            else   
                result.push(<PNew>[ Изображения с номером {imgNumber} нет ]</PNew>)
            textData=textData.substr(textData.indexOf(obj)).replace(obj,'')
            
          })
        result.push(<PNew>{textData}</PNew>)
        return result
      }

    render (){
        const firlds = global.genFields(this,this.Mainfields,1)
        const {SelfData,textData} = this.state
        const {match} = this.props
        if(SelfData.ID == null && this.props.match.params.ID_OBJECT!='new') return <div/>
        return (
            <Cont className="container-fluid p-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/News">Назад</Link></li>
                    </ol>
                </nav>

                <div className="row">
                    <div className="col-md-6 col-lg-4 ">
                       
                        <Input_image showMain makeMain={(i)=>this.makeMain(i)} value={SelfData.IMAGE} onChange={(filesToUpload,filesToDelete)=>{
                                
                                this.filesToUpload=[...this.filesToUpload,...filesToUpload]
                                this.filesToDelete=[...this.filesToDelete,...filesToDelete]
                                
                            }}/>


                        

                        {firlds}
                        <small className="text-muted mt-3" >Дата новости</small>
                        <input type='date' className='form-control' ref={ref=>this.input.DATE_CREATE = ref}/>
                        <div className="btn btn-primary btn-sm mt-3" onClick={()=>this.Save()}>Сохранить</div>
                        <div className="btn btn-danger btn-sm mt-3 ms-2" onClick={()=>this.Delete()}>Удалить</div>
                    </div>
                    <div className="col-md-4 col-lg-6 "  style={{
                        maxWidth:'360px'
                    }}>

                        <h3>Предпросмотр</h3>
                        <Scrolled>
                        {this.generateText(textData)}
                        </Scrolled>
                    </div>
                </div>
               
                
            </Cont>
        )
    }
}

const Cont = styled.div`
height:calc(100vh - 50px);
overflow-y:scroll;
overflow-x:hidden;
`
const ImgNew = styled.img`
width: 100%;
height: 150px;
object-fit: cover;
border-radius: 10px;
`

const PNew = styled.p`
font-size:11px;
`

const Scrolled = styled.div`
width:100%;
height:600px;
overflow-y:auto;
border:3px solid rgb(230,230,230);
border-radius:20px;
padding:20px;
`

const mapDispatchToProps = (dispatch) => {
    return {
      openPopUp: (arg) => dispatch(openPopUp(arg)), 
    };
  };
  
  
  const mapStateToProps = (store) => {
    return {
    };
  };
  
  export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NewsItem));
  
  
