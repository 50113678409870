import React from 'react';
import styled from 'styled-components';

export default class Input extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
        this.key = global.randomString()
        this.startValue=null
    }

    componentDidMount() {
        this.ready(this.props)
    }
    
    componentWillReceiveProps(nextProps) {
        this.ready(nextProps)
    }

    ready(props){
        const {Name,Ref,Value} = props
        if(this.startValue!=Value)
            Ref[Name].value=Value
    }
    
    render (){
        const {title,name,Ref,type} = this.props
        return ([
            <Label for={this.key} key={this.key+"L"}>{title}</Label>,
            type=='textarea'?
            <TextAreaView id={this.key} key={this.key+"I"}
            ref={ref=>Ref[name]=ref}
            className="form-control"
            {...this.props}/> :
            <InputView id={this.key} key={this.key+"I"}
            ref={ref=>Ref[name]=ref}
            className="form-control"
            {...this.props}/>
        ])
    }
}

const Label = styled.label`
font-size:11px;
opacity:0.7;
display:block;
`

const InputView = styled.input`
font-size:13px;
border: 1px #f2f5f5 solid;
width:100%;
margin-bottom:10px;
`
const TextAreaView = styled.textarea`
font-size:13px;
border: 1px #f2f5f5 solid;
width:100%;
margin-bottom:10px;
height:60px;
resize:none;
`
