import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {openPopUp} from '../../../actions/PopUpActions'

import POP_slider from './POP_slider';

class Sliders extends React.Component {
  constructor(props) {
      super()
      this.state = {
        filter:null
      } 
      
  }
  componentDidMount(){
   
   
  }

  componentWillUnmount(){
    
  }


  render (){
    const {filter} = this.state
    const {SLIDERS} = this.props

    return (
      <MainView>
        <div className='row row-cols-lg-4 row-cols-md-2 row-cols-sm-1'>
          {SLIDERS.map(slider =>
              <div className='col'>
                <Card.View onClick={()=>this.props.openPopUp(//окошко редактирования
                    <POP_slider ID_OBJECT={slider.ID} multiple={slider.IS_MULTIPLE} />
                  )}>
                  
                  <h5>{slider.TITLE}</h5>

                  <Card.Slide AllCount={slider.IMAGES_URL.length} src={`${global.URL_host}img/${slider.IMAGES_URL[0]}`}/>
                  {slider.IMAGES_URL.length<=1?null:
                    slider.IMAGES_URL.map((slide,index)=>
                      index==0?null:
                      <Card.SlideMini AllCount={slider.IMAGES_URL.length-1} index={index-1} src={`${global.URL_host}img/${slide}`}/>
                    )
                  }
                </Card.View>
              </div>
            )}
        </div>
      </MainView>
      )
  } 
}

const Card = {
  View:styled.div`
  padding:20px;
  background:white;
  box-shadow:10px 10px 30px rgba(0,0,0,0.05);
  border-radius:10px;
  cursor:pointer;
  margin-bottom:20px;
  position:relative;
  transition:.3s;
  float: left;
width: 100%;

  & h5{
    font-size:14px;
    height:30px;
  }
  &:hover{
    transform:scale(1.01);
  }
  `,
  Slide:styled.img`
  width: 100%;
  height: ${props=>props.AllCount==1?'225px':'170px'};
  object-fit: cover;
  border-radius: 10px;
  `,
  SlideMini:styled.img`
  margin-top:5px;
  width: calc((100% - ${props=>props.AllCount-1} * 5px) / ${props=>props.AllCount});
  height: 50px;
  margin-left:${props=>props.index==0?'0px':'5px'};
  object-fit: cover;
  border-radius: 10px;
  `
}

const MainView = styled.div`

`


const mapDispatchToProps = (dispatch) => {
    return {
      openPopUp: (arg)=>dispatch(openPopUp(arg))
    }
  }


const mapStateToProps = (store) => {
   
    return {
      SLIDERS : store.page.SLIDERS
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Sliders)

