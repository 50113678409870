
  const initialState = {
    TEXTS:[],
    SLIDERS:[],
  };
  
  export function pageReducer(state = initialState, action) {
   

    switch (action.type) {
      case "GET_TEXTS":
        let TEXTS = {}
        action.payload.map(txt=>{
          TEXTS[txt.NAME]={}
          TEXTS[txt.NAME]=txt
        })
        return {
          ...state,
          TEXTS
        };

        case 'JUST_SET_VALUE':
        let temp_state={}
        Object.assign(temp_state,state)
        temp_state[action.key] = action.payload
        
        return temp_state
      

      default:
        return state;
    }
  }