export function getTexts(arg) {
    return (dispatch) => {

        global.API_task({
            operation:"getTexts"
        })
        .then(res => {
            dispatch({
                type: "GET_TEXTS",
                payload:res
            })
        })
        

    }
}

export function getSliders(arg) {
    return (dispatch) => {
        
    global.API_task({
          operation:'getDataFromTable',
          Table:'SLIDERS'
      }).then(data => {
  
        data.forEach((d,i)=>{
          if(data[i].IMAGES_URL==null || data[i].IMAGES_URL =='')
            data[i].IMAGES_URL = []
          else
            data[i].IMAGES_URL = JSON.parse(data[i].IMAGES_URL.replaceAll('/',''))
        })
  
        dispatch({
          type: 'JUST_SET_VALUE',
          payload:data,
          key:'SLIDERS'
        })
      })
      
    }
  }

