import React from 'react';
import styled from 'styled-components';


export default class Input extends React.Component {
    constructor() {
        super()
        this.state = {
        }
        this.key = global.random()
        this.startValue=null
    }

    componentDidMount() {
        this.ready(this.props)
    }
    
    componentWillReceiveProps(nextProps) {
        this.ready(nextProps)
    }

    ready(props){
        const {Name,Ref,Value} = props
        if(this.startValue!=Value)
            Ref[Name].value=Value
    }
    
    render (){
        const {Title,Placeholder,onClick,onChange,onKeyUp,Name,Ref,Disabled,Style,Type} = this.props
        return ([
            <Label for={this.key} key={this.key+"L"}>{Title}</Label>,

            Type=="textarea"?
            <TextAreaView style={Style} id={this.key} key={this.key+"I"}
              placeholder={Placeholder==null?Title:Placeholder}
              type={Type}
              ref={ref=>Ref[Name]=ref}
              disabled={Disabled}
              onClick={(e)=>{try{onClick(e)}catch{}}}
              onChange={(e)=>{try{onChange(e)}catch{}}}
              onKeyUp={(e)=>{try{onKeyUp(e)}catch{}}}
              className="form-control"/>
            :
              <InputView style={Style} id={this.key} key={this.key+"I"}
              placeholder={Placeholder==null?Title:Placeholder}
              type={Type}
              ref={ref=>Ref[Name]=ref}
              disabled={Disabled}
              onClick={(e)=>{try{onClick(e)}catch{}}}
              onChange={(e)=>{try{onChange(e)}catch{}}}
              onKeyUp={(e)=>{try{onKeyUp(e)}catch{}}}
              className="form-control"/>
        ])
    }
}

const Label = styled.label`
font-size:11px;
opacity:0.7;
display:block;
`

const InputView = styled.input`
font-size:13px;
border: 1px #f2f5f5 solid;
width:100%;
margin-bottom:10px;
`
const TextAreaView = styled.textarea`
font-size:13px;
border: 1px #f2f5f5 solid;
width:100%;
height:100px;
resize:none;
margin-bottom:10px;
`