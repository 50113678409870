import React from 'react';
import { connect } from 'react-redux';
import { openPopUp } from '../../actions/PopUpActions';
import Alert from '../PopUp/Views/Alert'
import Header from './parts/Header';
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import News from './News';
import NewsItem from './News/NewsItem';
import Expo from './Expo';
import ExpoItem from './Expo/ExpoItem';
import ExpoItemElement from './Expo/ExpoItemElement';
import Texts from './Texts';
import Map from './Map';
import Sliders from './Sliders';



export default class pages extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
        
      }


    render (){
        
        return (
            <Router key="mainRouter" >
                <Header/>
                <Switch>

                <Route path="/expo/:ID_OBJECT/item/:ID_ITEM">
                    <ExpoItemElement/>
                </Route>

                
                <Route path="/expo/:ID_OBJECT">
                    <ExpoItem/>
                </Route>

                <Route path="/expo">
                    <Expo/>
                </Route>

                <Route path="/sliders">
                    <Sliders/>
                </Route>



                <Route path="/texts">
                    <Texts/>
                </Route>
                
                

              



                
                <Route path="/news/:ID_OBJECT">
                    <NewsItem/>
                </Route>

                <Route path="/news">
                    <News/>
                </Route>

              

                

                <Route path="/">
                    <p>Система администрирования мобильного приложения</p>
                </Route>
                
                </Switch>
          </Router>
        )
    }
}
