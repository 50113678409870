import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import ScrollContainer from 'react-indiana-drag-scroll'
import {getTexts} from "../../../actions/PageActions"
import {openPopUp,closePopUp} from "../../../actions/PopUpActions"
import Input from '../../components/Input';

class SelectExpos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedObject:{
                type:null,
                id:null
            },
            Expos:[]
        }
        this.getExpos()
    }

    async getExpos(){
        let Expos = []
        let Queue = []

        await global.API_task({
            operation:"getExpos"
        }).then(res => {
            res.forEach((expo,i)=>{
                res[i].IMAGE=JSON.parse(expo.IMAGE)
            })
            Expos=res
        })

        Expos.forEach((Expo,i)=>{
            Expos[i].childes=[]
            Queue.push(
                global.API_task({
                    operation:"getExposItems",
                    ID_EXPO:Expo.ID
                }).then(items => {
                    items.forEach((item,i)=>{
                        items[i].IMAGE=JSON.parse(item.IMAGE)
                    })
                    Expos[i].childes=items
                    this.setState({Expos})
                })
            )
        })

        Promise.allSettled(Queue).then(()=>{

        })



    }

    openExpos(obj){
        let Expos = this.state.Expos

        let index = Expos.findIndex(expo => expo.ID == obj.ID)
        Expos[index].isOpen = Expos[index].isOpen==null ? true : !Expos[index].isOpen
        this.setState({Expos})
        
    }

    Save(){
        this.props.closePopUp()
        this.props.callBack(
            this.state.selectedObject
        )
    }


    render (){
        const {selectedObject,Expos} = this.state

        return(
            <POP className="unselectable">
                <p>Выберите вложенный объект</p>
                <Scroll>
                    {Expos.map(expo=>(
                        <Obj.View onClick={()=>{
                            this.openExpos(expo)
                            this.setState({selectedObject:{
                                type:"EXPOS",
                                id:expo.ID
                            }})
                        }}
                        isSelected={selectedObject.type=="EXPOS" && selectedObject.id==expo.ID}>
                            <Obj.Img src={`${global.URL_Main}img/${expo.IMAGE[0]}`}/>
                            <Obj.Title>{expo.TITLE}</Obj.Title>
                            {expo.isOpen === true?
                                expo.childes.length==0?<small>Пусто</small>:
                                expo.childes.map(item=>(
                                    <Obj.View
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                        this.setState({selectedObject:{
                                            type:"EXPO_ITEMS",
                                            id:item.ID
                                        }})
                                    }}
                                    isSelected={selectedObject.type=="EXPO_ITEMS" && selectedObject.id==item.ID}>
                                        <Obj.Img src={`${global.URL_Main}img/${item.IMAGE[0]}`}/>
                                        <Obj.Title>{item.TITLE}</Obj.Title>
                                    </Obj.View>
                                ))
                            :null}
                        </Obj.View>
                    ))}
                    </Scroll>

                <Btn float="left" className="btn btn-sm btn-primary" onClick={()=>this.Save()}>Выбрать</Btn>
                <Btn float="right" className="btn btn-sm btn-leight" onClick={()=>this.Delete()}>Отмена</Btn>
            </POP>
        )
    }
}



const POP = styled.div`
width: 500px;
padding: 20px;
`

const Btn = styled.div`
float:${props=>props.float};
margin-bottom:15px;
width:calc(50% - 5px);
`

const Scroll = styled.div`
height:70vh;
width:100%;
overflow:scroll;
border-radius:10px;

`
const Obj = {
    
    Title:styled.div`
    font-size:12px;
    height:40px;
    overflow:hidden;
    `,
    Img:styled.img`
    position:absolute;
    object-fit:cover;
    width:40px;
    height:40px;
    border-radius:10px;
    background:gray;
    left:10px;
    `,
    View:styled.div`
    cursor:pointer;
    background:${props=>props.isSelected?`var(--color-accent)`:`rgba(0,0,0,0.05)`};
    color:${props=>props.isSelected?`white !important`:`black`};
    margin-bottom:10px;
    transition:.3s;
    border-radius:10px;
    padding-left:60px;
    position:relative;
    padding-top:10px;
    padding-bottom:10px;
    padding-right:10px;
    ${props=>props.isSelected?`
    & ${Obj.View}{
        color:white !important;
    }
    `:null};
    `,
}



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
       
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(SelectExpos);

