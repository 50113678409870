import { encode } from "base-64"
import axios from 'axios';

//if(window.location.hostname=="localhost"){
    global.serverURL_Main="http://kovalenko.lpn.su/server/"; 
    global.URL_Main="http://kovalenko.lpn.su"; 


    global.URL_host = global.URL_Main+'/'
    global.URL_server = global.URL_host+'server/'
    global.URL_API = global.URL_server+'task.php?'
  //}else{  
  //  global.serverURL_Main="/server/"; 
   // global.URL_Main=document.location.protocol+"//"+document.location.host+"/";
  //}
  global.serverURL=global.serverURL_Main+"task.php?";

  global.API={
    SEND_MAIL:"https://3.dsf.z8.ru/API/fileManager/sendMail.php",
    DOMAIN_CHECK:"https://3.dsf.z8.ru/API/AllowedDomains/checkDomain.php"
  }

  global.Auth={
    LOGIN:null,
    PASSWORD:null,
    ID:null
  }

  global.genFields=(Context,fields,STATUS)=>genFields(Context,fields,STATUS);
  global.randomString=()=>randomString()
  global.makeNormalDate=(Date,format)=>makeNormalDate(Date,format);
  global.API_task=(GET_ARGS_ARRAY)=>API_task(GET_ARGS_ARRAY);
  global.API_files=(operation,files,data)=>API_files(operation,files,data);
  global.OTHER_API_task=(URL,GET_ARGS_ARRAY)=>OTHER_API_task(URL,GET_ARGS_ARRAY);
  global.makeNormalDate=(Date,format)=>makeNormalDate(Date,format);
  global.checkReqvireds=(arrayReqv,ignorArray)=>checkReqvireds(arrayReqv,ignorArray);
  global.FIO=(fio,count)=>FIO(fio,count);
  global.okonchanie=(count,t1,t2,t3)=>okonchanie(count,t1,t2,t3);
  global.phoneFormatter=(phone)=>phoneFormatter(phone);
  global.random=()=>random();
  global.cookie={
    set:(key,val)=>setCookie(key,val),
    get:(key)=>getCookie(key),
  }
  global.isThetChatMuted=(idChat)=>isThetChatMuted(idChat)

  function isThetChatMuted(idChat){
    let MutedChats = global.cookie.get("MutedChats")
    if(MutedChats == null)
        MutedChats=[]
    else
        MutedChats = JSON.parse(MutedChats)
    
    let index = MutedChats.findIndex(chat=>chat==idChat)
    return index>=0?true:false
    
  }

  function random() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}
function randomString() {
  var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

function genFields(THIS,fields,STATUS){

  var arr=[];
  fields.map((field)=>{
      var input=null;
      switch(field.type){

          case "textarea":input = <textarea style={field.style!=null?field.style:{}} key={"sdcsdfcs"+field.name} onKeyUp={field.onChange} disabled={STATUS!=1} ref={ref => THIS.input[field.name] = ref}  className={"form-control "+field.className} autoComplete="new-password" />
              break;


          case "select":input = <select style={field.style!=null?field.style:{}} key={"svfvg"+global.getRandomInt(100000000000)} onChange={field.onChange} disabled={STATUS!=1} ref={ref => THIS.input[field.name] = ref} className={"form-control "+field.className} autoComplete="new-password">
            {field.values!=null || field.VALUE!=null?
              field.values.map((value)=>{
                if(value.value==null && value.VALUE!=null){
                  value.value=value.ID;
                  value.text=value.VALUE;
                }
              return <option  key={"wdcsd"+global.getRandomInt(100000000000)} value={value.value}>{value.text!=null?value.text:value.value}</option>
              }):null}
          </select>
              break;

       
          case "file":input = <input style={field.style!=null?field.style:{}} disabled={STATUS!=1} ref={ref => THIS.input[field.name] = ref} type="file" className={"form-control "+field.className} autoComplete="new-password" />
          break;

          default:
            case "input":input = <input style={field.style!=null?field.style:{}} onKeyUp={field.onChange} disabled={STATUS!=1} ref={ref => THIS.input[field.name] = ref} className={"form-control "+field.className} autoComplete="new-password" />
          break;

          
      }
      
      

      arr.push(
      <div>
          <small className="text-muted">{field.descr}</small>
          {input}
      </div>
      )
  })
  return arr;
}


  function phoneFormatter(phone){
    if(phone==null) return null
    if(phone.length<11) return "+"+phone
    let phArr = phone.split('')
    return `+${phArr[0]} (${phArr[1]}${phArr[2]}${phArr[3]}) ${phArr[4]}${phArr[5]}${phArr[6]}-${phArr[7]}${phArr[8]}-${phArr[9]}${phArr[10]}`
  }

  
function okonchanie(count,t1,t2,t3){
  if(count%10 >1 && count%10 <5 && !(count>=10 && count<=15)){
    return t2;
  }else if((count%10 >=5 && count%10 <=9)||count%10 ==0 || (count>=10 && count<=15)){
    return t3;
  }else{
    return t1;
  }
}


function makeNormalDate(Date_,format){

    try{
    var y = Date_.getFullYear();
    }catch{
  var tmpD=Date_;
  Date_=Date_.replace(/ /g,"T")
      Date_=new Date(Date_);
     
      if(Date_.getFullYear()>0){}else
        Date_=new Date("1998-11-26T"+tmpD);
  
       
        var y = Date_.getFullYear();
  
    }
   
    var m = Date_.getMonth()+1; if(m<10) m="0"+m;
    var d = Date_.getDate();   if(d<10) d="0"+d;
  
    var H = Date_.getHours();   if(H<10) H="0"+H;
    var M = Date_.getMinutes();   if(M<10) M="0"+M;
    var S = Date_.getSeconds();   if(S<10) S="0"+S;
    var I = Date_.getMilliseconds();  
  
    format= format.replace("yyyy",y);
    format= format.replace("dd",d);
    format= format.replace("mm",m);
  
    format= format.replace("H",H);
    format= format.replace("M",M);
    format= format.replace("S",S);
    format= format.replace("I",I);
   
    return format;
    
}


function checkReqvireds(arrayReqv,ignorArray=[]){
  var isOk=true;
  try{
  Object.keys(arrayReqv).forEach(key => {
      try{
    var itIgnor=false;
    for(var i=0;i<ignorArray.length;i++){
      if(ignorArray[i]==key){
        itIgnor=true;
        break;
      }
    }
    
      if(!itIgnor&&((arrayReqv[key].value==null  || arrayReqv[key].value.length==0 || arrayReqv[key].value===-1 || arrayReqv[key].value==-100) && key!='file')){
          arrayReqv[key].classList.add("border-danger");
         
          isOk=false;
      }else{
          
          arrayReqv[key].classList.remove("border-danger");
      }
    }catch{}
  });
}catch{}
  return isOk;
}

function OTHER_API_task(URL,GET_ARGS_ARRAY){
  return API_task(GET_ARGS_ARRAY,URL+"?")
}

function API_task(GET_ARGS_ARRAY,URL){
  global.setIsLoading(true)
  var API_URL = URL==null?global.serverURL:URL
 
 
  console.log("")
  console.log(API_URL)
  console.log(GET_ARGS_ARRAY)
  
  var Authorization =global.Auth.LOGIN!=null && URL==null? 
  'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
  :null


  var headers={}
  if(Authorization==null)
    headers={
      'Content-Type': 'application/json',
    }
  else
    headers= {
      'Content-Type': 'application/json',
      'Authorization':'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
    }
  
  return new Promise((resolve,reject)=>{
    axios({
      method:'post',
      url:API_URL,
      data:GET_ARGS_ARRAY,
      headers: headers
    }).then( response => {
  
      console.log("");
      console.log("result ")
      console.log(response.data);
     
      if(response.data.error!=undefined){
        console.log("");
        console.error("Ошибка ");
        console.error(response.data.error);
        reject()
      }else{
        resolve(response.data.result)
      }
    }).catch(error=>{
      reject(error)
    }).finally(()=>{
      global.setIsLoading(false)
    })
  })
  
}

function API_files(operation,files,GET_ARGS_ARRAY={}){
  console.log("");
  console.log(operation)
  console.log(files)

  return new Promise((resolve,reject)=>{
    var API_URL = global.serverURL
    
    var formData = new FormData();
    for(let i=0;i<files.length;i++){
      formData.append('file'+i, files[i]);
    }
   

    var ARGS="";
         
    Object.keys(GET_ARGS_ARRAY).forEach(function (key) {
        ARGS+="&";
      var itJSON=false;
      try{
        if(JSON.stringify(GET_ARGS_ARRAY[key])[0]=="{"){
          itJSON=true;
          }
      }catch{}
      if(itJSON)
        GET_ARGS_ARRAY[key]=JSON.stringify(GET_ARGS_ARRAY[key]);
      ARGS=ARGS+key+"="+GET_ARGS_ARRAY[key]
    });
    
    console.log("")
    console.log(API_URL+ARGS)
    console.log(GET_ARGS_ARRAY)

    

    var Authorization =global.Auth.LOGIN!=null?
    'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
    :null


    var headers={}
    if(Authorization==null)
      headers={
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    else
      headers= {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization
      }

      
    axios({
      method:'post',
      url:API_URL+"operation="+operation+ARGS,
      headers: headers,
      data:formData
    }).then( response => {
      
      console.log("");
      console.log("result "+operation)
      console.log(response.data);
     
      if(response.data.error!=undefined){
        console.log("");
        console.error("Ошибка "+operation);
        console.error(response.data.error);
        reject()
      }else{
        resolve(response.data.result)
      }
    }).catch(error=>{
      debugger
      reject(error)
    })
  })
}

function setCookie(name,value,days=365) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

    
function FIO(fio,count=1){
  let result=fio
  try{
    let array = fio.split(' ');
    switch(array.length){
      case 1: result = array[0]; break
      case 2:
        if(count==0)
            result = array[0] 
        if(count==1)
            result = array[0] + ' ' + array[1][0] + '. ' 
        if(count==2)
            result = array[0] + ' ' + array[1] 
          break
      default:
        case 3:
          if(count==0)
            result = array[0] 
          if(count==1)
            result = array[0] + ' ' + array[1][0] + '. ' + array[2][0] + '.'
          if(count==2)
            result = array[0] + ' ' + array[1] + ' ' + array[2][0] + '.'
          break
      
    }

  }catch{}
  return result
}
