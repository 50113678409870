import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import Item from './Item';
import Input_image from '../../components/Input_image';
import axios from "axios"
import { encode } from "base-64"
import {openPopUp} from "../../../actions/PopUpActions"
import {setIsLoading} from "../../../actions/LoadingWindowActions"
import Alert from "../../PopUp/Views/Alert"
import QRCode from 'qrcode.react'


//экспонат - детализация
class ExpoItemElemet extends React.Component {
    constructor(props) {
        super()
        this.state = {
            NewsData:[],
            SelfData:{},
            imgUrl:null
        }
        
        this.filesToUpload=[]
        this.filesToDelete=[]

        this.Main_img=null
        this.input={}

        this.Mainfields=[
            {
                name:"TITLE",
                descr:"Название"
            },
            {
                name:"DESCR_SM",
                descr:"Краткое описание",
                type:"textarea",
                style:{
                    resize:"none",
                    height:60
                }
            },
            {
                name:"DESCR_LG",
                descr:"Полное описание",
                type:"textarea",
                style:{
                    resize:"none",
                    height:200
                }
            }
        ]
    }

    

    componentDidMount() {
        if(this.props.match.params.ID_ITEM!="new"){
            global.API_task({
                operation:"getExposItems",
                ID_EXPO:this.props.match.params.ID_OBJECT,
                ID:this.props.match.params.ID_ITEM
            }).then(res => {
                
                res[0].IMAGE=JSON.parse(res[0].IMAGE)
                
                this.setState({SelfData:res[0]})
                this.Mainfields.map((field)=>{
                    this.input[field.name].value=res[0][field.name]!=null?res[0][field.name]:null
                })
            })
        }
    }
    
    Delete(){
        let data={
            operation:"deleteExpoItem",
            ID:this.props.match.params.ID_ITEM
        }
        global.API_task(data).then(() => {
            window.location.replace(`../`)
        })
    }

    Save(){
        if(!global.checkReqvireds([this.input.TITLE])) return

        let data={
            operation:"createUpdateExpoItem",
            ID_EXPO:this.props.match.params.ID_OBJECT,
        }

        this.Mainfields.map((field)=>{
            data[field.name]=this.input[field.name].value;
        })
        if(this.props.match.params.ID_ITEM=="new"){
        
            if(this.filesToUpload.length==0 ) {
                this.props.openPopUp(<Alert text="Нужно выбрать хотябы одно изображение для экспоната"/>)
                return
            }

            global.API_task(data).then(res => {
                    let form = new FormData();
                    for(let i=0;i<this.filesToUpload.length;i++){
                        form.append('file'+i, this.filesToUpload[i])
                    }

                    axios({
                        method:'post',
                        url:global.serverURL+`operation=setExpoIMAGE&ID=${res[0].ID}&type=EXPO_ITEMS`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization:'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
                        },
                        data:form
                    }).then(() => {
                        
                        window.location='../'
                    })
                

                
            })

        }else{
            data.ID=this.props.match.params.ID_ITEM
            

            
            global.API_task(data).then(res => {

                if(this.filesToDelete.length>0){
                    global.API_task({
                        operation:"deletePhoto",
                        ID:this.props.match.params.ID_ITEM,
                        type:"EXPO_ITEMS",
                        filesToDelete:JSON.stringify(this.filesToDelete)
                    })
                }

                
                if(this.filesToUpload.length==0 ) {
                    //window.location.reload()
                    return
                }
                
                


                    let form = new FormData();
                    for(let i=0;i<this.filesToUpload.length;i++){
                        form.append('file'+i, this.filesToUpload[i])
                    }


                    axios({
                        method:'post',
                        url:global.serverURL+`operation=setExpoIMAGE&ID=${res[0].ID}&type=EXPO_ITEMS`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization:'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
                        },
                        data:form
                    }).then( res => {
                        
                        window.location.reload()
                    })
                

                
            })
        }
    }

    downloadQR(){
        const {SelfData,ExpoItems} = this.state

        const canvas = document.getElementById("QRCode");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `QR ${SelfData.TITLE}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    

    async chooseAudio(files){
        this.props.setIsLoading(true)
        await new Promise((r,j)=>{
            setTimeout(()=>r(),2000)
        })
        const {SelfData} = this.state
        let form = new FormData();
        form.append('audio', files[0])
        
        axios({
            method:'post',
            url:global.serverURL+`operation=setExpoElemAudio&ID=${SelfData.ID}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization:'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
            },
            data:form
        }).then( (res) => {
            console.error(res) 
            if(res.data.error!=null){
                alert(res.data.error)
            }else{
                window.location.replace(`./${SelfData.ID}`)
            }
        }).finally(()=>{
            this.props.setIsLoading(false)
        })
    }

    

    deleteAudio(){
        const {SelfData} = this.state
        global.API_task({
            operation:"deleteExpoElemAudio",
            ID:SelfData.ID
        }).then(res => {
            window.location.replace(`./${SelfData.ID}`)
        })
    }

    changeVisible(next_visible){
        const {SelfData} = this.state
        global.API_task({
            operation:"changeVisible",
            TYPE:'EXPO_ITEMS',
            ID:SelfData.ID,
            IS_VISIBLE:next_visible
        }).then(res => {
           this.componentDidMount()
        })
    }

    makeMain(i){
        const {SelfData} = this.state
        
        var SelfData_tmp  = SelfData.IMAGE.splice(0)
        var mainImg  = SelfData_tmp[i]
        SelfData_tmp.splice(i,1)
        SelfData_tmp=[mainImg,...SelfData_tmp]
        
        global.API_task({
            operation:"setImgOrder",
            TYPE:'EXPO_ITEMS',
            ID:SelfData.ID,
            IMGS_ORDER:JSON.stringify(SelfData_tmp)
        }).then(res => {
           this.componentDidMount()
        })
    }

    render (){
        const firlds = global.genFields(this,this.Mainfields,1)
        const {SelfData} = this.state
        const {match} = this.props
        
        return (
            <Cont className="container-fluid p-3">
                

                <div className="row">
                    <div className="col-md-6 col-lg-4 ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                
                                <li className="breadcrumb-item"><Link to={`/expo/${match.params.ID_OBJECT}`}>Назад</Link></li>
                            </ol>
                        </nav>
                        {this.props.match.params.ID_ITEM=="new"?null:
                        <div style={{position:"relative",height:128}}>
                            
                                <QRCode
                                style={{position:"absolute",left: -17}}
                                    id="QRCode"
                                    value={`{"objectType":"EXPO_ITEM","ID":${this.props.match.params.ID_ITEM},"ID_EXPO":${match.params.ID_OBJECT
                                    }}`}
                                    includeMargin={true}
                                />
                            
                            <br></br>
                            {SelfData.IS_VISIBLE==1?
                                <small className='btn btn-primary'
                                style={{
                                    position: 'absolute',
                                    left: 109,
                                    top: 15
                                }}
                                onClick={()=>this.changeVisible(0)}>Объект виден</small>
                            :
                                <small className='btn btn-light' style={{
                                    position: 'absolute',
                                    left: 109,
                                    top: 15
                                }}
                                onClick={()=>this.changeVisible(1)}>Объект скрыт</small>
                            }
                            <small style={{
                                position: 'absolute',
                                left: 109,
                                bottom: 34
                            }}>Ссылка на объект</small>
                            <QrDownload onClick={()=>this.downloadQR()}>Скачать QR  </QrDownload>
                        </div>
                        }
                        
                        <Input_image showMain makeMain={(i)=>this.makeMain(i)} value={SelfData.IMAGE} onChange={(filesToUpload,filesToDelete)=>{
                            this.filesToUpload=[...this.filesToUpload,...filesToUpload]
                            this.filesToDelete=[...this.filesToDelete,...filesToDelete]
                        }}/>
                        <small className="text-muted mb d-block">Аудио гид</small>
                        {SelfData.AUDIO!=null? // Аудио гид ------
                            <div className="row m-0 mt-1">
                                <div className="col-4 p-0 pe-2">
                                    <a target="blank" href={`${global.URL_Main}/audio/${SelfData.AUDIO}`}>
                                        <div className="btn btn-sm btn-primary w-100">Прослушать</div>
                                    </a>
                                </div>
                                <div className="col-4 p-0 pe-1 ps-1">
                                    <div className="btn btn-sm btn-danger w-100" onClick={()=>this.deleteAudio()}>Удалить</div>
                                </div>
                                <div className="col-4 p-0 ps-2">
                                    <label for="AudioGid" className="btn btn-sm btn-light w-100" >Заменить</label>
                                    <input type="file" hidden id="AudioGid" accept="audio/mp3" onChange={(e)=>this.chooseAudio(e.target.files)}/>
                                </div>
                            </div>
                            
                            :
                            <div className="row m-0 mt-1">
                                <div className="col-6 p-0 ">
                                    <label for="AudioGid" className="btn btn-sm btn-light w-100">Добавить аудио файл</label>
                                    <input type="file" hidden id="AudioGid" accept="audio/mp3" onChange={(e)=>this.chooseAudio(e.target.files)}/>
                                </div>
                            </div>
                        }
                        
                        {firlds}
                        <div className="btn btn-primary btn-sm mt-3" onClick={()=>this.Save()}>Сохранить</div>
                        <div className="btn btn-danger ms-2 btn-sm mt-3" onClick={()=>this.Delete()}>Удалить</div>
                    </div>
                    
                </div>
               
                
            </Cont>
        )
    }
}

const Cont = styled.div`
height:calc(100vh - 50px);
overflow-y:scroll;
overflow-x:hidden;
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (arg) => dispatch(openPopUp(arg)), 
        setIsLoading: (arg) => dispatch(setIsLoading(arg)), 
    };
  };
  
  
  const mapStateToProps = (store) => {
    return {
    };
  };
  
  export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ExpoItemElemet));
  
  
  const QrDownload = styled.a`
  position: absolute;
  left: 110px;
  bottom: 13px;
  `