import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import Item from './Item';



class News extends React.Component {
    constructor(props) {
        super()
        this.state = {
            NewsData:[]
        }

    }

    

    componentDidMount() {
        global.API_task({
            operation:"getNews"
        }).then(res => {
            res.forEach((r,i)=>{
                res[i].IMAGE=JSON.parse(res[i].IMAGE)
            })
            this.setState({NewsData:res})
        })
    }


    render (){
        const {NewsData} = this.state
        return (
            <Cont className="container-fluid p-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/news/new">Создать новость</Link></li>
                    </ol>
                </nav>

                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5">

                    {NewsData.map(news=>
                        <Link  to={`/News/${news.ID}`}>
                            <Item data={news} className="col"/>
                        </Link>
                    )}
                
                </div>
                
            </Cont>
        )
    }
}

const Cont = styled.div`
height:calc(100vh - 50px);
overflow-y:scroll;
overflow-x:hidden;
`


const mapDispatchToProps = (dispatch) => {
    return {
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(News));

