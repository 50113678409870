


export const CHECK_LOGIN = 'CHECK_LOGIN';

export function getSelfInfo(arg) {
  return (dispatch) => {

  
    if(arg.LOGIN==null || arg.PASSWORD==null) {
      dispatch({
        type: CHECK_LOGIN,
        payload:{
          IS_LOGIN:false
        },
      })
      return
    }

    global.Auth={
      LOGIN:arg.LOGIN,
      PASSWORD:arg.PASSWORD
    }
   

    global.API_task({
      operation:"getSelfInfo"
    })
    .then(res => {
      
      let data_=res
      if(data_!=null && data_[0]!=null && data_[0].ID>0){
        data_[0].IS_LOGIN=true;
        data_=data_[0];

        global.Auth={
          LOGIN:arg.LOGIN,
          PASSWORD:arg.PASSWORD,
          ID:data_.ID
        }

        global.startShedul()

      }else
        data_={IS_LOGIN:false}

        
      dispatch({
        type: CHECK_LOGIN,
        payload:data_,
      })
    })   
    .catch(error => console.error(error))




  };
}