import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import Item from './Item';



class Expo extends React.Component {
    constructor(props) {
        super()
        this.state = {
            ExpoData:[]
        }

    }

    

    componentDidMount() {
        global.API_task({
            operation:"getExpos"
        }).then(res => {
            
            this.setState({ExpoData:res})
        })
    }

    ReOrder(NEW_ORDER_NUMBER,ID_EXPO){
        global.API_task({
            operation:"ReOrder",
            TYPE:"EXPOS",
            ID:ID_EXPO,
            NEW_ORDER_NUMBER
        }).then(res => {
            
            this.componentDidMount()
        })
    }


    render (){
        const {ExpoData} = this.state
        return (
            <Cont className="container-fluid p-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/expo/new">Создать экспозицию или коллекцию</Link></li>
                    </ol>
                </nav>

                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5">

                    {ExpoData.map(expo=>
                        <Link  to={`/expo/${expo.ID}`}>
                            <Item data={{...expo,allCount:ExpoData.length}} ReOrder={(e)=>this.ReOrder(e,expo.ID)} className="col"/>
                        </Link>
                    )}
                
                </div>
                
            </Cont>
        )
    }
}

const Cont = styled.div`
height:calc(100vh - 50px);
overflow-y:scroll;
overflow-x:hidden;
`


const mapDispatchToProps = (dispatch) => {
    return {
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Expo));

