import { combineReducers } from 'redux';
import { pageReducer } from './page';
import { userReducer } from './user';
import { popupReducer } from './popup';
import { loadingWindowReducer } from './loadingWindow';

export const rootReducer = combineReducers({
  page: pageReducer,
  user: userReducer,
  popups: popupReducer,
  loadingWindow: loadingWindowReducer,
});