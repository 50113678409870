import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import ScrollContainer from 'react-indiana-drag-scroll'
import {getTexts} from "../../../actions/PageActions"
import {openPopUp} from "../../../actions/PopUpActions"
import MarksView from './Marks'


const mapWidth = 1920
const mapHeight = 1080

class Map extends React.Component {
    constructor(props) {
        super()
        this.state = {
            Resize:20,
            Marks:[],
            selectedMark:null
        }

        this.input={}
        this.getMarks()
    }

    componentDidMount(){
        let {Resize} = this.state
        let elem = this.ScrollContainer.getElement()

        let offsetWidth = elem.offsetWidth
        let offsetHeight = elem.offsetHeight

        let window_HorizonatlCenter = offsetWidth/2
        let window_VerticalCenter = offsetHeight/2

        let map_HorizonatlCenter = (mapWidth*(Resize/100+0.5)+65)/2
        let map_VerticalCenter = (mapHeight*(Resize/100+0.5)+65)/2
        
        
        let horizontal_scroll = map_HorizonatlCenter - window_HorizonatlCenter
        let vertical_scroll = map_VerticalCenter - window_VerticalCenter

        elem.scrollLeft=horizontal_scroll
        elem.scrollTop=vertical_scroll
        
    }


    getMarks(){
        global.API_task({
            operation:"getMarks"
        }).then(res => {
            this.setState({Marks:res,selectedMark:null})
        })
    }

    Resize(nextResize){
        let {Resize} = this.state

        Resize+=nextResize
        
        Resize = Math.floor(Resize/10)*10

        if(Resize<0) Resize = 0
        if(Resize>200) Resize = 200

        this.setState({Resize})
        
        
    }

    createMark(X,Y){
        global.API_task({
            operation:"createMark",
            X:Math.floor(X),
            Y:Math.floor(Y),
        }).then(res => {
            this.getMarks()
            this.props.openPopUp(
                <MarksView
                ID_OBJECT={res[0].ID}
                callBack={()=>this.getMarks()}/>
            )
        })
    }

    render (){
        const {Resize,Marks,selectedMark} = this.state

        return(
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-8 unselectable">
                        <h4>Карта</h4>
                        
                            
                            
                            <RangeButtons>
                                <RabgeBtn onClick={()=>this.Resize(25)}>+</RabgeBtn>
                                <RabgeBtn onClick={()=>this.Resize(-25)}>-</RabgeBtn>
                            </RangeButtons>

                            <ScrollContainer
                            ref={ref=>this.ScrollContainer=ref}
                            className="scroll-container"
                            style={{
                                background:"rgba(231, 244, 255,0.5)",
                                height:"calc(100vh - 150px)",
                                borderRadius:10
                            }}>
                                <MapBg 
                                ref={ref=>this.MapBg=ref}
                                onClick={(e)=>{
                                    const {scrollTop,scrollLeft} = this.ScrollContainer
                                    let x = e.pageX + scrollLeft - this.MapBg.offsetLeft,
                                    y = e.pageY + scrollTop - this.MapBg.offsetTop
                                    x = x /(Resize / 100 + 0.5)
                                    y = y /(Resize / 100 + 0.5)

                                    console.error(`${x};${y}`)
                                    this.createMark(x,y)
                                    
                                }} size={Resize} >
                                    <img src="./img/usa.svg"/>
                                    {Marks.map(mark=>(
                                        <MapMark
                                        onMouseEnter={()=>{
                                            this.setState({selectedMark:mark.ID})
                                        }}
                                        
                                        className={selectedMark == mark.ID? "selected":""}
                                        key={`mark_${mark.ID}`}
                                        x={mark.X*(Resize / 100 + 0.5)}
                                        y={mark.Y*(Resize / 100 + 0.5)}
                                        onClick={(e)=>{
                                            e.stopPropagation()
                                            
                                            this.props.openPopUp(
                                                <MarksView
                                                ID_OBJECT={mark.ID}
                                                callBack={()=>this.getMarks()}/>
                                            )
                                        }}>
                                            <MapMarkView/>
                                            <MapMarkText>{mark.NAME_NUMBER}</MapMarkText>
                                            <MapMarkTitle>{mark.TITLE}</MapMarkTitle>
                                        </MapMark>
                                    ))}
                                    
                                </MapBg>
                            </ScrollContainer>
                       
                    </div>
                    <div className="col-4">
                        
                        <h4>Список меток</h4>
                        <ScrollView>
                        {Marks.map(mark=>(
                            <ListMark
                            onMouseEnter={()=>{
                                this.setState({selectedMark:mark.ID})
                            }}
                            className={selectedMark == mark.ID? "selected":""}
                            key={`markList_${mark.ID}`}
                            onClick={(e)=>{
                                e.stopPropagation()
                                
                                this.props.openPopUp(
                                    <MarksView
                                    ID_OBJECT={mark.ID}
                                    callBack={()=>this.getMarks()}/>
                                )
                            }}>
                                {mark.NAME_NUMBER!= null ? <b>{mark.NAME_NUMBER}</b> : mark.ID}{mark.TITLE}
                            </ListMark>
                        ))}
                        </ScrollView>
                    </div>
                </div>
            </div>
        )
    }
}

const ScrollView = styled.div`
height:calc(100vh - 93px);
overflow:auto;
padding-bottom:100px;
`

const MapBg = styled.div`
width:${props=>mapWidth*(props.size/100+0.5)}px;
height:${props=>mapHeight*(props.size/100+0.5)}px;
margin:100px;
border-radius:10px;
position:relative;
& img{
    width:100%;
    height:100%
}
`

const MapMarkTitle = styled.p`
    position:absolute;
    top:20px;
    transform:scale(0.5);
    color:white;
    line-height: 7px;
    font-size: 7px !important;
    font-weight:300 !important;
    text-align:center;
    width:100px;
    left:calc(50% - 50px);
    transition:.4s;
    opacity:0;
    z-index:-1;
`

const MapMark = styled.div`
    width:30px;
    height:30px;
    border-radius:100px;
    position:absolute;
    top:${props=>props.y - 15}px;
    left:${props=>props.x - 15}px;
    background:var(--color-accent);
    transition:transform .2s ease-in-out;
    text-align:center;
    font-weight:800;
    z-index:1;
    cursor:pointer;
    &:hover, &.selected{
        transform:scale(1.5);
    }
    &:hover div, &.selected div{
        margin-left:15px;
        margin-top:15px;
        height:0px;
        width:0px;
    }
    &:hover p, &.selected p{
        color:white;
        font-size: 16px;
    }
    &:hover ${MapMarkTitle}, &.selected ${MapMarkTitle}{
        opacity:1;
        top:32px;
        transform:scale(1);
    }
`

const ListMark = styled.div`
    background:rgb(250,250,250);
    border-radius:10px;
    margin-bottom:10px;
    padding:10px 20px;
    cursor:pointer;
    transition:.3s;
    &.selected{
        background:var(--color-accent);
        color:white;
    }
& b{
    width:35px; 
    display:inline-block;
    
}
`

const MapMarkView = styled.div`
    background:white;
    position:absolute;
    transition:.2s ease-in-out;
    border-radius:100px;
    margin-left:2px;
    margin-top:2px;
    height:26px;
    z-index:0;
    width:26px;
`



const MapMarkText = styled.p`
    display:block;
    line-height: 30px;
    color:var(--color-accent);
    font-size: 12px;
    position:absolute;
    transition:.2s ease-in-out;
    height:30px;
    z-index:1;
    width:30px;
`


const RangeButtons = styled.div`
width:40px;
height:80px;
background: rgba(163, 188, 210, 0.5);
backdrop-filter: blur(20px);
position:absolute;
z-index:10;
top:50vh;
border-radius:10px;
overflow:hidden;
margin-left:10px;
box-shadow:10px 10px 30px rgba(0,0,0,0.05)
`
const RabgeBtn = styled.div`
width: 40px;
text-align: center;
height: 40px;
line-height: 35px;
color: white;
font-size: 18px;
transition:.3s;
cursor:pointer;
font-weight: 700;
&:hover{
    background:rgb(255,255,255);
    color:rgb(163, 188, 210)
}
`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
       
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Map));

