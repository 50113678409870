import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router";




class Header extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }

        this.Pages=[
            {
                title:"Новости",
                url:"/news"
            },
            {
                title:"Экспозиции и коллекции",
                url:"/expo"
            },
            {
                title:"Контент",
                url:"/texts"
            },
            {
                title:"Медиа",
                url:"/sliders"
            },
            
        ]
    }

    exit(){
        const { cookies } = this.props;

        cookies.remove('LOGIN', { path: '/' });
        cookies.remove('PASSWORD', { path: '/' });
        cookies.remove('ID', { path: '/' });

        window.location.reload()
    }

    componentDidMount() {
        
    }


    render (){
        const { location } = this.props;
        
        return (
            <nav className="navbar navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="../">Коваленко</a>
                    <Menu className="breadcrumb ">
                        {
                            this.Pages.map(page=>
                                <li  className="breadcrumb-item ">
                                    {location.pathname.indexOf(page.url)==-1?
                                    <Link  to={page.url}>{page.title}</Link>
                                    :
                                    page.title
                                    }
                                </li>
                            )
                        }
                        
                    </Menu>
                    <div className="btn btn-primary btn-sm" onClick={()=>this.exit()}>Выход</div>
                </div>
            </nav>
        )
    }
}

const Menu = styled.ol`
position:absolute;
left:130px;
font-size:13px;
text-decoration:none;
`


const mapDispatchToProps = (dispatch) => {
    return {
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withCookies(Header)));

