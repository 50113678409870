
  const initialState = {
    nowLoading:false
  };
  
  export function loadingWindowReducer(state = initialState, action) {
    switch (action.type) {
      case "setIsLoading":
        return {
          ...state,
          nowLoading:action.payload
        };

      default:
        return state;
    }
  }