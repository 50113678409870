import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import ScrollContainer from 'react-indiana-drag-scroll'
import {getTexts} from "../../../actions/PageActions"
import {openPopUp,closePopUp} from "../../../actions/PopUpActions"
import Input from '../../components/Input';
import SelectExpos from './SelectExpos';

class Marks extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Marks:{
                DATA:[]
            },
        }
        this.input={}
        this.getSelfData()
        
    }

    componentDidMount(){
        this.input.TITLE.focus()
    }

    getSelfData(){
        if(this.props.ID_OBJECT == null) return

        global.API_task({
            operation:"getMarks",
            ID:this.props.ID_OBJECT
        }).then(res => {
            res[0].DATA = JSON.parse(res[0].DATA)
            if(res[0].DATA == null) res[0].DATA = []

            this.setState({Marks:res[0]})

            this.input.TITLE.value = res[0].TITLE
            this.input.DESCRIPTION.value = res[0].DESCRIPTION
            this.input.NAME_NUMBER.value = res[0].NAME_NUMBER

            this.loadDATA()
        })
    }

    loadDATA(){
        let {Marks} = this.state
        
        Marks.DATA.forEach((data,i)=>{
            let serverReqv = {}
            
            if(data.type == "EXPOS"){
                serverReqv={
                    operation:"getExpos",
                    ID:data.id
                }
            }else{
                serverReqv={
                    operation:"getExposItems",
                    ID:data.id
                }
            }
            
            global.API_task(serverReqv).then(res => {
                res[0].id = data.id
                res[0].type = data.type
                res[0].IMAGE = JSON.parse(res[0].IMAGE)

                Marks.DATA[i]=res[0]
                
                this.setState({Marks})
            })
        })
        
    }

    Save(){
        if(!global.checkReqvireds(this.input,["DESCRIPTION",'TITLE'])) return
        let DATA = this.state.Marks.DATA.map(data => {return {
            id:data.id,
            type:data.type
            }
        })


        global.API_task({
            operation:"updateMark",
            ID:this.props.ID_OBJECT,
            TITLE:this.input.TITLE.value,
            DESCRIPTION:this.input.DESCRIPTION.value,
            NAME_NUMBER:this.input.NAME_NUMBER.value,
            DATA:JSON.stringify(DATA)
        }).then(res => {
            this.props.closePopUp()
            this.props.callBack()
        })
    }

    Delete(){
        global.API_task({
            operation:"deleteMark",
            ID:this.props.ID_OBJECT
        }).then(res => {
            this.props.closePopUp()
            this.props.callBack()
        })
    }

    addObject(data){
        let Marks= this.state.Marks
        Marks.DATA.push(data)

        this.setState({Marks})
        setTimeout(()=>this.loadDATA(),10)
    }

    render (){
        const {Marks} = this.state

        return(
            <POP>
                <p>Изменить метку</p>
                <Input
                    Name="NAME_NUMBER"
                    Title="Индекс метки"
                    Type='number'
                    Placeholder=''
                    Ref={this.input}
                />
                <Input
                    Name="TITLE"
                    Title="Название метки"
                    Placeholder=""
                    Ref={this.input}
                />
                <Input
                    Name="DESCRIPTION"
                    Title="Описание"
                    Placeholder=""
                    Type="textarea"
                    Ref={this.input}
                />

                <Btn float="left" className="btn btn-sm btn-light w-100"
                onClick={()=>this.props.openPopUp(<SelectExpos callBack={(data)=>this.addObject(data)}/>)}>Прикрепить объект</Btn>
                

                {Marks.DATA.length>0?
                    <Scroll>
                        {Marks.DATA.map((obj,i)=>{
                            if(obj.ID==null) return null //объект еще не подгрузился

                            return(
                            <Obj.View>
                                <Obj.Img src={`${global.URL_Main}img/${obj.IMAGE[0]}`}/>
                                <Obj.Title>{obj.TITLE}</Obj.Title>
                                <Obj.Delete onClick={()=>{
                                    
                                     let MarksData= this.state.Marks.DATA.splice(0)
                                     MarksData.splice(i,1)
                             
                                     this.setState({Marks:{
                                         ...Marks,
                                         DATA:MarksData
                                     }})
                                }}>x</Obj.Delete>
                            </Obj.View>
                        )}
                        )}
                        
                    </Scroll>
                :null}

                
                <Btn float="left" className="btn btn-sm btn-primary" onClick={()=>this.Save()}>Сохранить</Btn>
                <Btn float="right" className="btn btn-sm btn-danger" onClick={()=>this.Delete()}>Удалить</Btn>
            </POP>
        )
    }
}



const POP = styled.div`
width: 350px;
padding: 20px;
`

const Btn = styled.div`
float:${props=>props.float};
margin-bottom:15px;
width:calc(50% - 5px);
`

const Scroll = styled.div`
height:200px;
width:100%;
overflow:scroll;
border-radius:10px;
padding-bottom:50px;
`

const Obj = {
    Delete:styled.div`
    font-size: 17px;
    position: absolute;
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 9px;
    cursor: pointer;
    text-align: center;
    font-weight: 100;
    left: 10px;
    line-height: 38px;
    z-index: 10;
    top: 10px;  
    opacity:0;
    transition:.2s ease-in-out;
    &:hover{
        transform:scale(1.1);
    }
    `,
    Title:styled.div`
    font-size:12px;
    height:40px;
    overflow:hidden;
    `,
    Img:styled.img`
    position:absolute;
    object-fit:cover;
    width:40px;
    height:40px;
    border-radius:10px;
    background:gray;
    left:10px;
    `,
    View:styled.div`
    cursor:pointer;
    background:${props=>props.isSelected?`var(--color-accent)`:`rgba(0,0,0,0.05)`};
    color:${props=>props.isSelected?`white !important`:`black`};
    margin-bottom:10px;
    transition:.3s;
    border-radius:10px;
    padding-left:60px;
    position:relative;
    padding-top:10px;
    padding-bottom:10px;
    padding-right:10px;
    &:hover div{
        opacity:1;
    }
    ${props=>props.isSelected?`
    & ${Obj.View}{
        color:white !important;
    }
    `:null};
    `,
}


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
       
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Marks);

