import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

class LoadingWindow extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isVisible:0,
            step:false,
            stepWidth:false
        }
        setInterval(()=>this.setState({step:!this.state.step}),1000)
        setTimeout(()=>{
            setInterval(()=>this.setState({stepWidth:!this.state.stepWidth}),500)
        },500)
        
        this.nextTimeOut = null

        
 
    }

    componentWillReceiveProps(nextProps) {
        const old_nowLoading = this.props.loadingWindow.nowLoading
        const {nowLoading} = nextProps.loadingWindow

        if(old_nowLoading && !nowLoading){
                this.setState({isVisible:1})
                clearTimeout(this.nextTimeOut)
                this.nextTimeOut = setTimeout(()=>this.setState({isVisible:0}),300)
            
        }else{
            if(!old_nowLoading && nowLoading){
                if(this.state.isVisible==0){
                    this.setState({isVisible:1})
                    clearTimeout(this.nextTimeOut)
                    this.nextTimeOut = setTimeout(()=>this.setState({isVisible:2}),300)
                }
            }
        }
            
    }

    
  

    render (){
        const {step,stepWidth,isVisible} = this.state
        const {nowLoading} = this.props.loadingWindow

        if(isVisible===0)
            return <div/>

        return [
            <BG key="BG" isVisible={isVisible}/>,
            <Window key="Window" isVisible={isVisible}>
                <Title>Загрузка . . .</Title>
                <Descr>Это может занять немного времени</Descr>
                <Loading>
                    <Anim step={step} />
                    <Anim step={!step} isRight={true}/>
                </Loading>
            </Window>
        ]
    }
}
const WindowSize ={
    width:300,
    height:130
}
const Window = styled.div`
width:${WindowSize.width}px;
height:${WindowSize.height}px;
border-radius:20px;
background:white;
position:fixed;
left:calc(50vw - ${WindowSize.width/2}px);
top:calc(50vh - ${WindowSize.height/2}px);
z-index:9999999;
padding:15px;
opacity:${props=>props.isVisible==2?1:0};
transition:.3s ease-in-out;
`

const Loading = styled.div`
width:${WindowSize.width-30}px;
height:30px;
border-radius:20px;
background:var(--color-accent);
bottom:15px;
position:absolute;
overflow:hidden;
`

const Title = styled.h3`

`
const Descr = styled.p`
margin-top:-10px;
color:rgb(50,50,50);
`

const Anim = styled.div`
width:${props=>props.step?`${WindowSize.width/2}px`:`20px`};
height:${props=>props.step?`${WindowSize.width/2}px`:`20px`};
background:#6093d5;
border-radius:200px;
position:absolute;
top:${props=>props.step?`-${WindowSize.width/4-5}px`:`5px`};

${props=>props.isRight?`right`:`left`}:${props=>props.step?`-${WindowSize.width/4-5}px`:`5px`};

transition:.9s ease-in-out;
`


const BG = styled.div`
opacity:${props=>props.isVisible==2?1:0};
width:100vw;
height:100vh;
background:rgba(0,0,0,0.4);
position:fixed;
left:0;
top:0;
z-index:9999998;
transition:0.3s ease-in-out;
`

const mapDispatchToProps = (dispatch) => {
    return {
    };
  };
  
  
  const mapStateToProps = (store) => {
    return {
      loadingWindow: store.loadingWindow
    };
  };
  
  export default connect(mapStateToProps,mapDispatchToProps)(LoadingWindow);
  
  