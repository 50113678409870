import React from 'react';
import styled from 'styled-components';


export default class Input_image extends React.Component {
    constructor(props) {
        super()
        this.state = {
            img_url:[]
        }

        this.input=[];
        this.filesToDelete=[]

        this.isModifited = false

        this.randomId="inp_"+global.random(10000);
        
       
    }


            

    componentDidMount(){
        if(this.props.value==null || this.props.value==undefined){
            this.setState({ img_url:[]})
            return
        }
        

        if(this.props.value!=null)
            this.setState({ img_url:this.props.value.map(f=>f={url:f}) })
        
        
    }

    componentWillReceiveProps(nextProps) {
        if(this.isModifited) return 

        if(nextProps.value==null || nextProps.value==undefined){
            this.setState({ img_url:[]})
            return
        }
        

        if(nextProps.value!=this.state.img_url)
            this.setState({ img_url:nextProps.value.map(f=>f={url:f}) })
        
    }

    onChange(target){
        if (target.files.length>0) {
            var currentFile=0
            var fr = new FileReader()
            fr.addEventListener("load",  ()=>{
                let img_url = this.state.img_url
                img_url.push({
                    url:fr.result,
                    fileData:target.files[currentFile]
                })

                currentFile++
                this.setState({img_url})
                if(currentFile<target.files.length)
                    fr.readAsDataURL(target.files[currentFile])
            }, false)

            fr.readAsDataURL(target.files[currentFile])
            
        }
        this.props.onChange(target.files,this.filesToDelete)
        this.isModifited=true
    }

    DeleteImg(index){
        let img_url = this.state.img_url
        if(img_url[index].fileData==null){//если это ранее загруженый файл
            this.filesToDelete.push(img_url[index].url)
        }
        img_url.splice(index,1)
        this.setState({img_url})

        let filesToUpload = img_url.filter(file=>file.fileData!=null).map(file=>file.fileData)
        this.props.onChange(filesToUpload,this.filesToDelete)
        
    }

   

  

    render (){
        const {img_url} = this.state
        return (
            <Input_imageView>
                <ImgScroll>
                    <ImgContent>
                        {img_url.map((img,i)=>
                            <ImgView key={"photo"+i}>
                                {i==0 && this.props.showMain?
                                <ShowMain>Главная</ShowMain>
                                :null}
                                <Img id="preview_title" src={img.fileData==null?`${global.URL_Main}/img/${img.url}`:img.url}/>
                                <Delete onClick={()=>this.DeleteImg(i)}>x</Delete>

                                {i>0 && this.props.showMain?
                                <MakeMain onClick={()=>this.props.makeMain(i)}>На главную</MakeMain>
                                :null}
                            </ImgView>
                        )}                    </ImgContent>
                </ImgScroll>
                <label for={this.randomId} className="btn btn-sm btn-light d-block">Выберите изображения 1000x1000</label>
                <input id={this.randomId} multiple={true} hidden={true} accept="image/x-png,image/jpeg,image/jpg" type="file"
                onChange={(e)=>this.onChange(e.target)}/>
            </Input_imageView>
        )
    }
}

const ShowMain = styled.div`
font-size: 8px;
background: var(--color-accent);
color: white;
text-align: center;
text-transform: uppercase;
transition: .3s;
position: absolute;
width: 100%;
z-index:1;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
`

const MakeMain = styled.div`
font-size: 8px;
background: var(--color-accent);
color: white;
bottom:0;
text-align: center;
text-transform: uppercase;
transition: .3s;
position: absolute;
width: 100%;
cursor:pointer;
opacity:0;
z-index:1;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
&:hover{
    padding-top:5px;
    padding-bottom:5px;
}
`


const Img = styled.img`
width: 100px;
height: 100px;
border-radius: 10px;
transition: .3s;
object-fit: cover;

`

const Delete = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 10px;
    background: #ab4040;
    border: 2px solid white;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    text-align: center;
    color: white;
    font-weight: 400;
    line-height: 18px;
    transition: .3s;
    cursor: pointer;
    opacity:0;
    &:hover{
        transform:scale(1.1)
    }
`

const ImgView = styled.div`
width: 100px;
height: 100px;
position:relative;
display:inline-block;
margin-right:20px;
&:hover ${Img}{
    opacity:0.8;
}
&:hover ${Delete}{
    opacity:1;
}
&:hover ${MakeMain}{
    opacity:1;
}
`



const ImgScroll = styled.div`
overflow-x:scroll;
border-radius: 10px;
margin-bottom: 5px;

`
const Input_imageView = styled.div`

`
const ImgContent = styled.div`
width:max-content;
position:relative;

`