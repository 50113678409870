import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";




export default class Item extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
        this.input ={}
    }

    //экспозиция

    componentDidMount() {
        const {data}= this.props
        this.input.value=data.ORDER_NUMBER
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.data.ORDER_NUMBER!=this.input.value){
            this.input.value=nextProps.data.ORDER_NUMBER
        }
    }

    

    renderOrder(){
        const {data}= this.props
        let array=[]
        for(let i=0;i<data.allCount;i++){
            array.push( <option key={`opt_${i}`}  value={i}>{i+1}</option> )
        }
        return array
    }

    render (){
        const {data} = this.props
        return (
            <Cont className="p-3 " {...data}>
                <Select ref={ref=>this.input=ref} onChange={(e)=>this.props.ReOrder(e.target.value)}>
                    {this.renderOrder()}
                </Select>
                <Img src={`${global.URL_Main}/img/${JSON.parse(data.IMAGE)[0]}`}/>
                <Title>{data.TITLE}</Title>
                <Desrc>{data.DESCR_SHORT}</Desrc>
               
            </Cont>
        )
    }
}

const Select = styled.select`
height: 40px;
width: 40px;
background: var(--color-accent);
color: white;
border-radius: 8px;
-webkit-appearance: none;
appearance: none;
position: absolute;
border: 5px solid white;
margin-left: -10px;
margin-top: -10px;
text-align: center;
padding: 0px;
padding-left: 8px;
font-size: 11px;
cursor: pointer;
transition:.3s;
&:focus{
    outline:none
}
&:hover{
    border: 2px solid white;
    padding-left: 10px;
}
`

const Cont = styled.div`
${props=>props.IS_VISIBLE==1?`
opacity:1;
box-shadow:10px 10px 30px rgba(0,0,0,0.05);
`:`
opacity:0.4;
`}

border-radius:10px;
color:black;
`

const Img = styled.img`
width:100%;
height:200px;
object-fit:cover;
border-radius:10px;
`

const Title = styled.p`
margin-top:10px;
margin-bottom:0px
`

const Desrc = styled.p`
margin-top:10px;
font-size:12px;
margin-bottom:0px;
`




