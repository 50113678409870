import React from 'react';
import firebase from "firebase/app";
import styled from 'styled-components';
import { openPopUp } from "../actions/PopUpActions"
import { withCookies } from 'react-cookie';
import Alert from './PopUp/Views/Alert'
import Balac from "../img/Balac.jpg"

import { connect } from 'react-redux';



class Login extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
       
      }

    passwordSendToMail(){
        if(!global.checkReqvireds(this.input,["PASSWORD"])) return
        global.API_task({
            operation:"passwordSendToMail",
            LOGIN:this.input.LOGIN.value
        }).then(res=>{
            if(res=="ok"){
                this.props.openPopUp(<Alert text="Восстановленный пароль выслан на почту пользователя"/>)
            }
        })
    }

    signIN(){
        this.setState({errorMSG:null})
        if(!global.checkReqvireds(this.input)) return

        global.API_task({
            operation:"Login",
            PASSWORD:this.input.PASSWORD.value,
            LOGIN:this.input.LOGIN.value
        }).then(res=>{
            if(res.length==0){
                this.setState({errorMSG:"Неверный логин или пароль"})
                return
            }
            const { cookies } = this.props
            cookies.set("LOGIN", res[0].LOGIN, { path: '/' })
            cookies.set("PASSWORD", res[0].PASSWORD, { path: '/' })
            cookies.set("ID", res[0].ID, { path: '/' })
            window.location.reload()
        })
    }

    render (){
        
        return (
           
            <Cont className="Page Login ">
                <div ref={ref => (this.recaptcha = ref)}></div>
                <div className="row">
                    <div className="col-lg-4 col-md-3"/>
                    <div className="col-lg-4 col-md-6">
                        <div className="card w-100 mx-auto mt-5 border-0 shadow" >
                            <div className="card-body">
                            
                                <h5 className="card-title">Вход в систему</h5>
                               
                                <p className="card-text">Администрирование мобильного приложения</p>
                                <input placeholder="Логин" ref={ref=>this.input.LOGIN = ref} className="form-control mb-3"/>
                                <input placeholder="Пароль" ref={ref=>this.input.PASSWORD = ref} className="form-control mb-3"/>
                                <a onClick={()=>this.signIN()} className="btn btn-primary w-25">Вход</a>
                                
                            </div>
                        </div>
                        {this.state.errorMSG!=null?
                            <div className="alert alert-danger mt-3" role="alert">
                                {this.state.errorMSG}
                            </div>
                        :null}
                        
                        
                    </div>
                    <div className="col-lg-4 col-md-3"/>
                </div>
            </Cont>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
      openPopUp: (content) => dispatch(openPopUp(content)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
       
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Login));



const Cont = styled.div`
background-image:url('${Balac}');
background-size: cover;
`