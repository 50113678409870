import React, { Component } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSelfInfo } from '../actions/UserAct';
import { getTexts,getSliders} from '../actions/PageActions';
import Login from "./Login"
import {setIsLoading} from "../actions/LoadingWindowActions"
import '../global'
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";

import { withCookies } from 'react-cookie';
import Main from './pages';
import Header from './pages/parts/Header';
import News from './pages/News';
import PublicMap from './pages/Map/PublicMap';

class App extends Component {
  constructor(props) {
    super();
    this.state={
      display:0,
    }

    const { cookies } = props;
    global.setIsLoading=(arg)=>props.setIsLoading(arg)
    
    props._getSelfInfo({
      LOGIN: cookies.get("LOGIN"),
      PASSWORD: cookies.get("PASSWORD"),
    });

    

    global.startShedul = () => {
      this.props.getTexts()
      this.props.getSliders()
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    };

    
  }

  componentDidMount() {
    
  }
  componentWillReceiveProps(nextProps){
    const oldPopUps = this.props.PopUps.length
    const newPopUps = nextProps.PopUps.length

    if(oldPopUps>0 && newPopUps==0){
      this.setState({display:1})
      setTimeout(()=>this.setState({display:0}),500)
      
    }else if(oldPopUps==0 && newPopUps>0){
      this.setState({display:1})
      setTimeout(()=>this.setState({display:2}),5)
      
    }
    
  }
  

  render() {
    const { user,PopUps} = this.props
    const {display} = this.state

    let content = null 
    if (user.SELF_INFO.IS_LOGIN == null) {
      content =  <div></div>
    } else if (user.SELF_INFO.IS_LOGIN)
    content = <Main/>
    else content = <Login/>
  
    return([
    <Router>
      <Switch>
        <Route path="/public_map">
            <PublicMap/>
        </Route>

        <Route path="/">
            {content}
        </Route>
      </Switch>
    </Router>,
    <BlurBg display={display}/>,
    PopUps.map(popUp=>{
      return popUp
    }),
      
    ])
   
    
    
  }
}

const BlurBg = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    display:${props=>props.display==0?`none`:`block`};
    background: rgba(0,0,0,0.3);
    opacity:${props=>props.display==2?1:0};
    transition:opacity .5s;
    width: 100vw;
    z-index:10;
`


const mapDispatchToProps = (dispatch) => {
  return {
    _getSelfInfo: (arg) => dispatch(getSelfInfo(arg)), 
    getTexts: (arg) => dispatch(getTexts(arg)), 
    setIsLoading: (arg) => dispatch(setIsLoading(arg)), 

    getSliders: (arg) => dispatch(getSliders(arg)), 
  };
};


const mapStateToProps = (store) => {
  return {
    user: store.user,
    PopUps: store.popups.windows
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(App));

