import {
  CHECK_LOGIN
} from '../actions/UserAct';


const initialState = {
  SELF_INFO:{}
  };
  

  export function userReducer(state = initialState, action) {
    switch (action.type) {
      case CHECK_LOGIN:
        return {
          ...state,
          SELF_INFO: action.payload
        };
  
      
  
      default:
        return state;
    }
  }