import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import ScrollContainer from 'react-indiana-drag-scroll'
import {getTexts} from "../../../actions/PageActions"
import {openPopUp,closePopUp} from "../../../actions/PopUpActions"
import Input from '../../components/Input';
import SelectExpos from './SelectExpos';

const width = window.outerWidth

class Marks extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Marks:{
                DATA:[]
            },
        }
        this.input={}
        this.getSelfData()
        
    }

    componentDidMount(){
       
    }

    getSelfData(){
        if(this.props.ID_OBJECT == null) return

        global.API_task({
            operation:"getMarks",
            ID:this.props.ID_OBJECT
        }).then(res => {
            res[0].DATA = JSON.parse(res[0].DATA)
            if(res[0].DATA == null) res[0].DATA = []

            this.setState({Marks:res[0]})

          
            this.loadDATA()
        })
    }

    loadDATA(){
        let {Marks} = this.state
        
        Marks.DATA.forEach((data,i)=>{
            let serverReqv = {}
            
            if(data.type == "EXPOS"){
                serverReqv={
                    operation:"getExpos",
                    ID:data.id
                }
            }else{
                serverReqv={
                    operation:"getExposItems",
                    ID:data.id
                }
            }
            
            global.API_task(serverReqv).then(res => {
                res[0].id = data.id
                res[0].type = data.type
                res[0].IMAGE = JSON.parse(res[0].IMAGE)

                Marks.DATA[i]=res[0]
                
                this.setState({Marks})
            })
        })
        
    }

    

    render (){
        const {Marks} = this.state

        return(
            <POP className='container-fluid'>
                <div className='row'>
                    <div className='col-md-5'>
                        <h4>{Marks.TITLE}</h4>
                        <p>{Marks.DESCRIPTION}</p>
                    </div>
                    <div className='col-md-7'>
                        <Scroll>
                            {Marks.DATA.map((obj,i)=>{
                                if(obj.ID==null) return null //объект еще не подгрузился

                                return(
                                <Obj.View onClick={()=>{
                                    
                                    let type = obj.type=='EXPOS'?'expo':'expo_item'
                                    debugger
                                    window.open(`https://muzey-sevastopol.ru/${type}/?id=${obj.ID}`, '_blank');
                                }}>
                                    <Obj.Img src={`${global.URL_Main}/img/${obj.IMAGE[0]}`}/>
                                    <Obj.Title>{obj.TITLE}</Obj.Title>
                                    <Obj.Descr>{obj.DESCR_SHORT}</Obj.Descr>
                                </Obj.View>
                            )}
                            )}
                            
                        </Scroll>   
                    </div>
                </div>
                
            </POP>
        )
    }
}



const POP = styled.div`
width: 1000px;
max-width:100%;
padding: 20px;
&::-webkit-scrollbar {
    width      : 0; 
    background : transparent  ;
}
`

const Btn = styled.div`
float:${props=>props.float};
margin-bottom:15px;
width:calc(50% - 5px);
`

const Scroll = styled.div`
height:600px;
width:100%;
overflow:scroll;
padding-bottom:150px;
padding-top:60px;
margin-top:-30px;
margin-bottom: -20px;
&::-webkit-scrollbar {
    width      : 0; 
    background : transparent  ;
}


`

const Obj = {
    Descr:styled.small`
    font-size:12px;
    overflow:hidden;
    font-weight:600;
    `,
    Title:styled.div`
    ${width>1000?`
    font-size:16px;
    `:`
    font-size:12px;
    `}
    overflow:hidden;
    `,
    Img:styled.img`
    position:absolute;
    object-fit:cover;
    
    border-radius:10px;
    background:gray;
    left:0px;
    ${width>1000?`
    width:150px;
    height:150px;
    `:`
    width:100px;
    height:100px;
    `}
    `,
    View:styled.div`
    cursor:pointer;
    color:${props=>props.isSelected?`white !important`:`black`};
    margin-bottom:10px;
    transition:.3s;
    border-radius:10px;
   
   
    position:relative;
    padding-top:10px;
    padding-bottom:10px;
    padding-right:10px;
    &:hover div{
        opacity:1;
    }
    ${width>1000?`
    padding-left:160px;
    height:170px;
    `:`
    padding-left:110px;
    height:110px;
    `}
    ${props=>props.isSelected?`
    & ${Obj.View}{
        color:white !important;
    }
    `:null};
    `,
}


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
       
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Marks);

