import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getSliders } from '../../../actions/PageActions';
import Table from '../../components/Table'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import Input_image_sliders from '../../components/Input_image_sliders'
import {GlobalFunctions as GF} from '../../../GlobalFunctions'





class POP_slider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter:null,
            ID_OBJECT:props.ID_OBJECT,
           
            Self:{},
            
        }
        this.filesToUpload=[]
        this.filesToDelete=[]
    }

    componentDidMount(){
       
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    

    getSelf(){
        
        let Self = this.props.SLIDERS.find(data => data.ID == this.state.ID_OBJECT)
       
        this.setState({Self})
        
    }

    Save(){
       
    
        let promises =[]
        if(this.filesToUpload.length>0)
        
            promises.push(GF.API_files({
                operation:'setIMAGE',
                Table:'SLIDERS',
                ID:this.props.ID_OBJECT
            },this.filesToUpload))

        if(this.filesToDelete.length>0)
            promises.push(GF.API_task({
                operation:'deleteIMAGE',
                ID:this.props.ID_OBJECT,
                Table:'SLIDERS',
                filesToDelete:JSON.stringify(this.filesToDelete)
            }))
        Promise.all(promises).then(()=>{
            this.props.getSliders()
        })
        this.props.closePopUp()
    }




    render (){
        const {Self} = this.state
        const {ID_OBJECT,multiple} = this.props
        
        return (
            <POP> 
                <h4>{Self.TITLE}</h4>
                <p>{Self.NAME}</p>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='mb-2' style={{height:140}}>
                            <Input_image_sliders multiple={multiple} value={Self.IMAGES_URL} onChange={(filesToUpload,filesToDelete)=>{
                                
                                this.filesToUpload=[...this.filesToUpload,...filesToUpload]
                                this.filesToDelete=[...this.filesToDelete,...filesToDelete]
                                
                            }}/>
                        </div>
                    </div>
                </div>

                <div className='btn btn-sm btn-light ' onClick={()=>
                    this.Save()}
                >Сохранить</div>

                
            </POP>
        )
    }
}


const POP = styled.div`
width:500px;
height:auto;
& p{
    font-size:12px;
}
& p b{
    font-size:14px;
    width:180px;
    font-weight:500;
    display:inline-block;
}
`

const Check = styled.img`
width:100%;
height:auto;
margin-bottom:20px;
border-radius:20px;
object-fit:contain;
`

const Scroll = styled.div`
width: calc(100% - 20px);
height: calc(100% - 20px);
overflow: auto;
position: absolute;
border-radius: 20px;
padding-bottom: 100px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),

        getSliders: (arg) => dispatch(getSliders(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        SLIDERS : store.page.SLIDERS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_slider);

